import BemClass from "@upsales/bemclass";
import Text from "components/Text";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import "./Shortcuts.scss";

export const OVERVIEW_ABOUT = "about";
export const OVERVIEW_APP = "app";
export const APP_DOCUMENTATION = "documentation";

export const CONTACT = "contact";

export const OVERVIEW_SHORTCUTS = [
    ["shortcut.app", OVERVIEW_APP],
    ["shortcut.about", OVERVIEW_ABOUT],
];

const Shortcuts = (props) => {
    const { translate, shortcuts = [] } = props;
    if (!shortcuts.length) return null;

    const bem = new BemClass("Shortcuts");
    return (
        <div className={bem.b()}>
            {shortcuts.map(([name, id], i) => {
                return (
                    <a key={`shortcut_${id}_${i}`} href={`#${id}`}>
                        <Text
                            className={bem
                                .elem("Link", "DropShadow--hoverable")
                                .b()}
                        >
                            {translate(name)}
                        </Text>
                    </a>
                );
            })}
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Shortcuts);

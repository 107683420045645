import BemClass from "@upsales/bemclass";
import Layout from "components/Layout";
import Logo from "components/Logo/Logo";
import Text from "components/Text";
import Title from "components/Title";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { APP_DOCUMENTATION } from "../Shortcuts/Shortcuts";
import "./AppDocumentation.scss";

const AppDocumentation = ({ translate }) => {
    const bem = new BemClass("AppDocumentation");
    return (
        <Layout className={bem.b()} id={APP_DOCUMENTATION} vertical>
            <Title size="lg">Introduction</Title>
            <Text>
                <p>
                    AC Control is a control system for various equipment,
                    especially ventilation units and heat pumps. It consists of
                    three parts:
                </p>
                <p>- the app, iOS or Android based</p>
                <p>
                    - a Linux based microcomputer connected to the equipment
                    (UNIT), normally Raspberry Pi (PI)
                </p>
                <p>- a central server, the AC Control Cloud (ACCC)</p>
                <p>
                    The PI controls and monitors the UNIT. It delivers data and
                    alarms to ACCC, it is also responsible for establishing and
                    keeping a secure tunnel to ACCC. If the UNIT lacks internet,
                    it will act as a WiFi Access Point to allow apps to control
                    parameters without ACCC.
                </p>
            </Text>
            <Title size="sm">Technical Overview</Title>
            <Text>
                <p>
                    A user logs in to the app and can manage their units. The
                    app communicates with ACCC and can read data and update
                    parameters. Update is performed through the tunnel from ACCC
                    to UNIT.
                </p>
                <p>
                    The PI is the information master: it decides identities and
                    characteristics for each sensor (a device which delivers
                    measured values) and each actuator (a controlling device).
                    The device information is delivered to ACCC at startup.
                    Changes in sensor values and actuator settings are also
                    regularly sent to ACCC where the data is stored.
                </p>
                <p>
                    Different UNITs have different device setups, depending on
                    the UNIT logic. An air/water heatpump is more complicated
                    than an air/air heat exchanger. The PI can communicate over
                    Modbus RTU, I2C, SPI etc. Some UNITs has an Arduino
                    controller attached which performs IO operations like fan
                    controlling and temperature sensor handling.
                </p>
                <p>
                    Communication PI {"->"} ACCC and app {"->"} ACCC is
                    performed over SHTTP which is a secure, high performance
                    protocol.{" "}
                </p>
            </Text>
            <Title size="lg">Unit Ownership</Title>
            <Text>
                <p>
                    Each UNIT has a written document associated with it. This
                    document has a printed QR code, including the numeric UNIT
                    identity. When the UNIT is prepared at the factory, the
                    database in ACCC receives an entry where the UNIT is present
                    without an owner.
                </p>
                <p>
                    Before installing a UNIT the owner shall download the app
                    from AppStore or GooglePlay. Open the app and register a new
                    user with a password and a full name. Click Done and you
                    will see a view with your full name. Tap "Scan new unit" and
                    scan the QR code in the UNIT document. You will be requested
                    to name the unit - then you are registered as the unit
                    owner.
                </p>
                <p>
                    Note that scanning of the QR code is possible only when the
                    machine is free. Scanning of a busy QR code will fail.
                </p>
            </Text>
            <Title size="sm">Guests</Title>
            <Text>
                <p>
                    An owner can share a UNIT with another user, who will have
                    GUEST rights to the UNIT. An owner can share units, a GUEST
                    can not. This is the only difference.
                </p>
                <p>
                    A useful example of sharing is when a ventilation unit owner
                    shares his unit with his service organisation.
                </p>
            </Text>

            <Title size="lg">A Tour Through The App</Title>
            <Title size="sm">First View</Title>
            <Text>
                There are some alternatives depending on the situation. One or
                many of these options may appear:
            </Text>
            <Text>
                <ol>
                    <li>
                        <b>Log In As (username)</b>
                        <p>
                            You will be logged-in as (username) which is the
                            user you logged in as the previous time. This is the
                            default way to log in.
                        </p>
                    </li>
                    <li>
                        <b>Log In As Another User</b>
                        <p>You will be prompted for username and password.</p>
                    </li>
                    <li>
                        <b>Create Account</b>
                        <p>
                            You will be prompted for username, password and full
                            name to create a new account.
                        </p>
                    </li>
                    <li>
                        <b>Forget Me On This Unit</b>
                        <p>
                            Your credentials are removed from the permanent
                            storage in the app's memory.
                        </p>
                    </li>
                    <li>
                        <b>Your are not connected to any network...</b>
                        <p>
                            You have no network connection. If your unit isn't
                            connected to any network it can provide its own WiFi
                            Access Point (hotspot mode, see definitions). In the
                            unit document there is another QR code printed with
                            a WiFi symbol in the middle. Open the Camera in your
                            mobile phone and scan the QR code and you will
                            connect to the unit's hotspot network. Reopen the
                            app and you will find option 6:
                        </p>
                    </li>
                    <li>
                        <b>Hotspot direct machine access</b>
                        <p>
                            You are now connected to the unit on the hotspot
                            network. You will be able to manage the unit in
                            almost the same way as if it is online connected to
                            ACCC. You cannot share the unit to a guest or manage
                            guests. You can update software to the unit, this is
                            otherwise handled automatically by connected units.
                        </p>
                    </li>
                </ol>
            </Text>
            <Text>
                <p>
                    When logged in, you will se one section with your own
                    machine(s), one section for each user with machines where
                    you are guest. Last comes Scan New Unit where you can gain
                    access to new units, followed by Software downloads which is
                    interesting only if you have machines without internet
                    access.
                </p>
                <p>
                    If you tap the first row with your name, you can edit your
                    own settings. Otherwise - tap a machine row.
                </p>
            </Text>

            <Title size="sm">Machine View</Title>
            <Text>
                Different unit types behave a bit different. There is always a
                first group with the following rows:
            </Text>
            <Text>
                <ol>
                    <li>
                        <b>Notifications For Me</b>
                        <p>
                            Turn on or off push notifications sent to me when
                            alarms or other notable events occur.
                        </p>
                    </li>
                    <li>
                        <b>Machine Parameters</b>
                        <p>
                            New view with machine-specific parameters. Here you
                            can change the unit's description and location. You
                            can also connect the unit to a local router - see
                            chapter Connect Unit.
                        </p>
                    </li>
                    <li>
                        <b>Unit Alarms</b>
                        <p>
                            Shows actual alarms. Tap Delete on an alarm to
                            acknowledge it. Persistant alarms will automatically
                            reappear.
                        </p>
                    </li>
                    <li>
                        <b>Ownership and Guests</b>
                        <p>
                            A view where you can share the unit to guests,
                            manage the guest list, deal with ownership etc.
                        </p>
                    </li>
                    <li>
                        <b>Advanced View</b>
                        <p>
                            Only visible if the unit supports advanced view. In
                            advanced view, more sensors/controllers will become
                            visible.
                        </p>
                    </li>
                </ol>
            </Text>
            <Text>
                <p>
                    After the first section comes one or more sections with
                    sensors and actuators, and/or groups with them. A sensor is
                    presented with its name and current value, an actuator is
                    presented with its name and current setting.
                </p>
                <p>
                    Tapping a sensor will open a view showing sensor details,
                    including optional possibilities to set alarm levels and
                    display historic values
                </p>
                <p>
                    Tapping an actuator will open a view where the user can
                    change the setting: different views depending on the
                    actuator type.
                </p>
            </Text>
            <Title size="sm">WiFi Setup</Title>
            <Text>
                <p>
                    When delivered, each UNIT is preset for starting in Hotspot
                    Mode, meaning that the PI creates a WiFi access point. This
                    network has its own unique name. Normally, the UNIT should
                    be connected to the internet and ACCC so the app can control
                    it from anywhere on the earth.
                </p>
                <p>
                    In the app, select the machine in the machine list, enter
                    Machine Parameters / Connect Unit. Enter SSID and passphrase
                    to the WiFi and tap Connect.
                </p>
            </Text>
            <Title size="sm">Manage Offline Units</Title>
            <Text>
                <p>
                    If the unit is located in an area without internet access:
                    to be able to keep the unit updated with the latest
                    software, make sure you have the latest version downloaded
                    to you phone. You find the Software Downloads at the end of
                    the machine list.
                </p>
                <p>
                    Since the unit is offline it has created its own Hotspot
                    network. Scan the Machine WiFi QR code with your phone
                    camera, this will make your phone attach to the hotspot
                    network. Open the AC Control app, on the login page you will
                    find Hotspot Direct Machine Access, tap it and you will find
                    an a bit smaller Machine View.
                </p>
                <p>
                    You cannot handle guests and ownerships, nor will
                    notifications work.
                </p>
                <p>
                    Tap Software update offline unit and you will see if your
                    unit requires an update.
                </p>
            </Text>
        </Layout>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(AppDocumentation);

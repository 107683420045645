import React from "react";
import PropTypes from "prop-types";
import "./Text.scss";

const sizes = ["sm", "md", "lg", "xl"];
const colors = ["grey", "white", "black", "green", "dark-grey", "yellow"];

const propTypes = {
    children: PropTypes.node,
    text: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf(sizes),
    color: PropTypes.oneOf(colors),
    center: PropTypes.bool,
    bold: PropTypes.bool,
};

const defaultProps = {
    size: "md",
    color: "grey",
};

class Text extends React.PureComponent {
    render() {
        const {
            children,
            text,
            color,
            size,
            noselect,
            center,
            bold,
            className,
            ...props
        } = this.props;
        const classes = ["Text"];
        classes.push("Text--" + size);
        if (color) classes.push("Text--" + color);
        if (center) classes.push("Text--center");
        if (bold) classes.push("Text--bold");
        if (noselect) classes.push("noselect");
        if (className) classes.push(className);
        return (
            <div {...props} className={classes.join(" ")}>
                {text || children}
            </div>
        );
    }
}

Text.sizes = sizes;
Text.colors = colors;
Text.defaultProps = defaultProps;
Text.propTypes = propTypes;
export default Text;

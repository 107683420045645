import BemClass from "@upsales/bemclass";
import app from "assets/app.png";

import Layout from "components/Layout";
import Text from "components/Text";
import Title from "components/Title";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { OVERVIEW_APP } from "../../components/Shortcuts/Shortcuts";
import "./Cloud.scss";

import google_play_en from "assets/GooglePlay/Play_EN.svg";
import app_store_en from "assets/AppStore/AppStore_EN.svg";

const Cloud = ({ translate }) => {
    const bem = new BemClass("Cloud");
    return (
        <Layout className={bem.b()} id={OVERVIEW_APP}>
            <div className={bem.elem("section").mod("left").b()}>
                <div className={bem.elem("text").b()}>
                    <Title size="lg" auto className={bem.elem("title").b()}>
                        {translate("default.appcloud.title", null, {
                            renderInnerHtml: true,
                        })}
                    </Title>
                    <Text className={bem.elem("sub").b()}>
                        {translate("default.appcloud.text")}
                    </Text>
                    <div className={bem.elem("download").b()}>
                        <a href="https://apps.apple.com/se/app/ac-control/id1597446103" target="_empty">
                            <img className={bem.elem('AppStore').b()} src={app_store_en} alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=se.accontrol" target="_empty">
                            <img className={bem.elem('GooglePlay').b()} src={google_play_en} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div
                className={bem.elem("section").mod("right").b()}
                id="CLOUD_APP"
            >
                <div className={bem.elem("img").b()}>
                    <img src={app} alt="App" />
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Cloud);

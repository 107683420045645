import BemClass from "@upsales/bemclass";
import React from "react";
import { connect } from "react-redux";
import "./Logo.scss";
import logo from "assets/ac_control.svg";


const Logo = ({small, medium, large, auto}) => {

    const bem = new BemClass("WseLogo");

    return <div className={bem.mod({small, medium, large, auto}).b()}>
        <img src={logo} alt="logo"/>
    </div>

}

export default connect()(Logo);
import { combineReducers } from "redux";
import { localizeReducer } from "react-localize-redux";

// import itemStateReducer from './itemState';
// import newsStateReducer from "./newsState";
// import productStateReducer from './productState';

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    localize: localizeReducer,
    // itemState: itemStateReducer,
    // news: newsStateReducer,
    // paperState: productStateReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;

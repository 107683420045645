import React from "react";

import { Switch } from "react-router-dom";
import AppliedRoute from "./AppliedRoute";
import Overview from "./Overview/Overview";

const RouteSwitch = ({ childProps, children }) => (
    <Switch>
        {Object.values(modules).map(({ path, exact, component, ...rest }) => {
            return (
                <AppliedRoute
                    key={path + "-comp"}
                    path={path}
                    exact={exact}
                    component={component}
                    props={{ ...childProps, ...rest }}
                />
            );
        })}
        {children}
    </Switch>
);

export const pathStart = "/";

export const modules = {
    overview: {
        name: "default.overview",
        path: pathStart,
        exact: true,
        component: Overview,
    },
};

export default RouteSwitch;

// import TopBar from "components/TopBar/TopBar";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import "typeface-open-sans";
import "typeface-quicksand";
import "typeface-roboto";
import "./App.scss";
import en from "./lang/en.json";
import sv from "./lang/sv.json";
import RouteSwitch from "./routes";

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, "g"), replacement);
};

class App extends React.Component {
  constructor(props) {
    super(props);

    props.initialize({
      languages: [{ name: "English", code: "en" }],
      translation: {},
      options: { renderToStaticMarkup, defaultLanguage: "en", renderInnerHtml: true, },
    });
    props.addTranslationForLanguage(en, "en");
    // props.addTranslationForLanguage(sv, "sv");
  }

  render() {
    return (
      <BrowserRouter>
        {/* <TopBar /> */}
        <RouteSwitch>
          <Route>
            <Redirect to="/"></Redirect>
          </Route>
        </RouteSwitch>
      </BrowserRouter>
    );
  }
}

export default withLocalize(App);

import BemClass from "@upsales/bemclass";
import Layout from "components/Layout";
import Logo from "components/Logo/Logo";
import Text from "components/Text";
import Title from "components/Title";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { OVERVIEW_ABOUT } from "../../components/Shortcuts/Shortcuts";
import "./About.scss";

const Cloud = ({ translate }) => {
    const bem = new BemClass("About");
    return (
        <Layout className={bem.b()} id={OVERVIEW_ABOUT} vertical>
            <div className={bem.elem("section").mod("left").b()}>
                <Logo />
                <div className={bem.elem("text").b()}>
                    <Title
                        center
                        size="lg"
                        auto
                        className={bem.elem("title").b()}
                    >
                        {translate("default.about.title", null, {
                            renderInnerHtml: true,
                        })}
                    </Title>
                    <Text center className={bem.elem("sub").b()}>
                        {translate("default.about.text")}
                    </Text>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state, props) => ({
    translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Cloud);

import BemClass from "@upsales/bemclass";
import React from "react";
import "./Layout.scss"

const Layout = ({ className, children, fullWidth, vertical, auto=true, ...rest}) => {
    const bem = new BemClass("Layout", className);
    return <div className={bem.mod({fullWidth, vertical, auto}).b()} {...rest}>{children}</div>;
};

export default Layout;

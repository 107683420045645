import AppDocumentation from "components/AppDocumentation/AppDocumentation";
import Footer from "components/Footer/Footer";
import Logo from "components/Logo/Logo";
import React from "react";
import About from "./About";
import Cloud from "./Cloud";
// import Shortcuts, { OVERVIEW_SHORTCUTS } from "components/Shortcuts/Shortcuts";

const Overview = () => {
    return [
        <About key={"about"} />,
        // <Shortcuts key={"shortcuts"} shortcuts={OVERVIEW_SHORTCUTS} />,
        <Cloud key={"clouds"} />,
        <AppDocumentation />,
        <Footer key={"footer"} />,
    ];
};

export default Overview;
